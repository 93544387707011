import { useQueryState } from "next-usequerystate";
import type { PaginationState } from "@tanstack/react-table";
import { sortingState } from "~/server/db-types";
import type { SortingState } from "@tanstack/react-table";
import { stringify, tryParse } from "~/components/query/jsurl-encode";
import { useEffect } from "react";

export const useTableInfo = (prefix: string, searchValue: string | null) => {
  const [pageIndex, setPageIndex] = useQueryState<number>(`${prefix}p`, {
    defaultValue: 0,
    parse: (value) => {
      return parseInt(value);
    },
    serialize: (value) => {
      return value.toString();
    },
  });
  const [pageSize, setPageSize] = useQueryState<number>(`${prefix}ps`, {
    defaultValue: 10,
    parse: (value) => {
      return parseInt(value);
    },
    serialize: (value) => {
      return value.toString();
    },
  });
  const [sortInfo, setSortInfo] = useQueryState<SortingState>(`${prefix}sort`, {
    defaultValue: [],
    parse: (value) => {
      // const jsonString = decodeURIComponent(value);
      // const answer = sortingState.safeParse(JSON.parse(jsonString));
      // return answer.success ? answer.data : [];
      const answer = sortingState.safeParse(tryParse(value, []));
      return answer.success ? answer.data : [];
    },
    serialize: (value: SortingState) => {
      // const jsonString = JSON.stringify(value);
      // return encodeURIComponent(jsonString);
      return stringify(value) ?? "";
    },
  });

  const setPagination = async (
    paginationInfo:
      | PaginationState
      | ((old: PaginationState) => PaginationState),
  ) => {
    if (typeof paginationInfo === "function") {
      paginationInfo = paginationInfo({
        pageIndex,
        pageSize,
      });
    }

    if (pageIndex !== paginationInfo.pageIndex) {
      await setPageIndex(paginationInfo.pageIndex);
    }
    if (pageSize !== paginationInfo.pageSize) {
      await setPageSize(paginationInfo.pageSize);
    }
  };

  const setSorting = async (
    sortInfoValue: SortingState | ((old: SortingState) => SortingState),
  ) => {
    if (typeof sortInfoValue === "function") {
      sortInfoValue = sortInfoValue(sortInfo);
    }

    await setSortInfo(sortInfoValue);
  };

  useEffect(() => {
    console.log(`muly:use-table-info:`, { searchValue });
    if (searchValue) {
      setPageIndex(0).catch(console.error);
    }
  }, [searchValue, setPageIndex]);

  return {
    pagination: {
      pageIndex,
      pageSize,
    },
    sortInfo,
    setPagination,
    setSorting,
    name: prefix,
  };
};
