"use client";

import type { GetServerSidePropsContext } from "next";
import type { Session } from "@supabase/auth-helpers-nextjs";
import { createPagesServerClient } from "@supabase/auth-helpers-nextjs";
import type { User } from "@supabase/supabase-js";
import { useState } from "react";
import { useRouter } from "next/router";
import { useConfigContext } from "~/components/common/config/config-context";
import { isSSR } from "~/utils/nextjs-utils";
import { Loading } from "~/components/common/Loading";
import { api } from "~/utils/api";
import { useQueryStateText } from "~/components/query/useQueryStateText";
import { useTableInfo } from "~/components/common/data-table/use-table-info";
import { Avatar } from "~/components/ui/avatar";
import { CardContent, CardFooter, Card } from "~/components/ui/card";
import { Button } from "~/components/common/ui/button";
import { Component } from "lucide-react";
import { cn } from "~/lib/utils";

export default function SelectOrganization() {
  const router = useRouter();
  const { userProfile, organizationId } = useConfigContext();
  const [redirected, setRedirected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(null);

  const [searchValue, searchControl] = useQueryStateText({
    key: "search",
    placeholder: "Search customers",
    defaultValue: "",
  });

  const tableInfo = useTableInfo("customers", searchValue);
  const { data, isFetched } = api.organization.getOrganizations.useQuery(
    {
      search: searchValue,
      tableParams: {
        pagination: tableInfo.pagination,
        sortInfo: tableInfo.sortInfo,
      },
    },
    {
      enabled: !!userProfile,
      placeholderData: (prev) => prev || { items: [], count: 0 },
    },
  );

  const maybeRedirectToLogin = async () => {
    if (!redirected && !isSSR()) {
      if (!userProfile) {
        console.log(`muly:Home: redirected to login`);
        await router.replace("/login");
        setRedirected(true);
      } else if (isFetched && data?.items.length === 1) {
        const orgId = data.items[0]!.id;
        console.log(`muly:Home: redirected to orgId ${orgId}`);
        await router.replace(`/${orgId}`);
        setRedirected(true);
      }
    }
    if (isLoading) {
      setIsLoading(false);
    }
  };

  const [isOpenOrganization, setIsOpenOrganization] = useState(false);
  const handleSelectOrganization = async () => {
    setIsOpenOrganization(true);
    console.log(`muly:handleSelectOrganization ${selectedOrganizationId}`);
    await router.push(`/${selectedOrganizationId}`);
    router.reload();
    setIsOpenOrganization(false);
  };

  maybeRedirectToLogin().catch(console.error);

  // if (redirected || isLoading || !projects) {
  if (redirected || isLoading || !isFetched) {
    console.log(`muly:index:SelectOrganization`, {
      redirected,
      isLoading,
      isFetched,
    });
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex items-center">
      <Card className="w-full max-w-lg mx-auto">
        <CardContent className="p-4 md:p-6">
          <div className="space-y-4">
            <h2 className="text-lg font-bold">Select an account</h2>
            <div className="space-y-4">
              {data?.items.map((org) => (
                <Card
                  key={org.id}
                  className={cn(
                    "flex items-center pt-2.5 pb-2.5 cursor-pointer",
                    { "bg-gray-100": selectedOrganizationId === org.id },
                  )}
                  onClick={() => setSelectedOrganizationId(org.id)}
                >
                  <div className="flex items-center">
                    <Avatar className="w-6 h-6">
                      <Component />
                      {/*<img*/}
                      {/*  alt="Logo"*/}
                      {/*  className="aspect-[1/1] rounded-full object-cover"*/}
                      {/*  height="32"*/}
                      {/*  src="/placeholder.svg"*/}
                      {/*  width="32"*/}
                      {/*/>*/}
                    </Avatar>
                    <div className="ml-2 leading-none">
                      <h3 className="font-semibold">{org.name}</h3>
                      {/*<p className="text-sm text-gray-500 dark:text-gray-400">*/}
                      {/*  Awesome SaaS company*/}
                      {/*</p>*/}
                    </div>
                  </div>
                  <div />
                </Card>
              ))}
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex p-4 md:p-6">
          <Button
            loading={isOpenOrganization}
            className="ml-auto"
            onClick={handleSelectOrganization}
            disabled={!selectedOrganizationId}
          >
            Choose Account
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

SelectOrganization.disableLayout = true;
SelectOrganization.disableNeedUser = true; // we need user, but we want to redirect to login page if not login
SelectOrganization.disableNeedOrganizationData = true;
SelectOrganization.expandSideMenu = false;
